export default {
    methods: {
        async getErrorCodeStrings(lang = 'en-US') {
            const locale = this.$cookie.get('locale') || lang

            await this.$store.dispatch(
                'website-setting/error-code/getErrorStrings',
                locale
            )
        },
    },
}
