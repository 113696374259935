
import errorCodeStrings from '~/mixins/errorCodeStrings'
export default {
    name: 'Blank',
    mixins: [errorCodeStrings],
    head() {
        return {
            link: [
                {
                    rel: 'icon',
                    type: 'image/x-png',
                    href: '/csl.png',
                },
            ],
        }
    },
    computed: {
        bgImage() {
            return '/assets/background.jpg'
        },
    },
    mounted() {
        // Fetch error code string
        this.getErrorCodeStrings()
    },
}
